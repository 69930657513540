import Helmet from "react-helmet"
import React from "react"
import Disclosure from "src/components/disclaimers";
import ExtendedHomepage from "src/components/extendedHomepage"
import HomepageLayout from "src/layouts/homepage"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import {isIeEdge,isChrome,isFirefox, isSafari} from "@tightrope/lpscripts/browserdetect"


const homepageData = import("./homepage.json");
const footer = <RawFooter></RawFooter>

export default function HomePage() {
  let disclosure = null;

  if(isIeEdge()){
      disclosure = <Disclosure></Disclosure>;
  }
 if(isFirefox()){
      disclosure = <Disclosure language="en-firefox"></Disclosure>;

  }
 if(isSafari()) {
     disclosure = <Disclosure></Disclosure>;
 }
  if(isChrome()){
        disclosure = <Disclosure></Disclosure>;
    }
  return(
    <HomepageLayout>
      <Helmet>
      <style type="text/css">{`
#disclaimers-module--disclaimers p {
  text-align: center;
  color: #777;
  font-size: 13px;
  width: 474px;
  line-height: 150%;
  margin: 0 auto 12px;
}

#disclaimers-module--disclaimers p a {
  color: #172a55;
  font-weight:500;
}

.extendedhomepage-module--middle #disclaimers-module--disclaimers p {
  color: #fff;
}

.extendedhomepage-module--bottom #disclaimers-module--disclaimers p {
  color: #777;
}

.extendedhomepage-module--middle #disclaimers-module--disclaimers p a {
  color: #bdfaff;
}

.extendedhomepage-module--bottom #disclaimers-module--disclaimers p a {
  color: #172a55;
}

.extendedhomepage-module--bannerLeft #disclaimers-module--disclaimers p {
  width: 474px;
}


        footer {
          position: relative;
          width: 100%;
          bottom: 2px;
          padding: 0;
          margin:0;
          z-index: 1;
        }
        footer ul {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap:wrap;
          color: #666;
          font-size: 12px;
          padding: 10px 0;
          list-style:none;
        }
        footer ul li {
          margin: 0;
        }
        footer ul li a {
          text-decoration: none;
          color: #666;
        }
        footer ul li:after {
          content: '|';
          padding: 0 4px;
        }
        footer ul li:last-child:after {
          content: none;
          padding: 0;
        }

        @media screen and (max-width: 1366px) {
.extendedhomepage-module--bannerLeft #disclaimers-module--disclaimers p {
  width: 450px;
}

      }

        @media screen and (max-width: 576px) {

        .extendedhomepage-module--bannerLeft #disclaimers-module--disclaimers p, .extendedhomepage-module--middle #disclaimers-module--disclaimers p, .extendedhomepage-module--bottom #disclaimers-module--disclaimers p {
          width: 90%;
        }
      }





        `}</style>
      <title>Cutting Edge AI | AnswerBuddy</title></Helmet>
      <ExtendedHomepage data={homepageData}>
      <div className="disc">{disclosure}</div>
      </ExtendedHomepage>
      <RawFooter></RawFooter>
    </HomepageLayout>
  )
}
